<div class="navWallet">
    <button mat-button [matMenuTriggerFor]="menuWallet">
        <img *ngIf="!noIcon" class="avatar-image" src="../../../../assets/images/svg/currency.svg">
        <strong><span *ngIf="noIcon">€</span> {{money/100 | currency:' ': true}}</strong>
        <span *ngIf="!noIcon" class="material-icons">
            arrow_drop_down
        </span>
    </button>
    <mat-menu #menuWallet="matMenu">
        <button mat-menu-item [routerLink]="['bullet']">{{'NAV_WALLET.SEE_MOVEMENTS' | translate }}</button>
        <button mat-menu-item [routerLink]="['payment-methods']">{{'PROFILE.PAYMENT_METHODS' | translate }}</button>
    </mat-menu>
</div>