import { Component, Input, OnInit } from '@angular/core';
import { SecondaryTitleComponent } from '../secondary-title/secondary-title.component';

@Component({
  selector: 'app-modal-header',
  templateUrl: './modal-header.component.html',
  styleUrls: ['./modal-header.component.scss'],
  standalone: true,
  imports:[
    SecondaryTitleComponent
  ]
})
export class ModalHeaderComponent implements OnInit {
  @Input() title = '';
  constructor() { }

  ngOnInit() {
  }

}
