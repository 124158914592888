import { UserService } from 'src/app/login/services/user/user.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class AuthGuardService  {
  constructor(public auth: UserService, public router: Router) { }
  canActivate(): boolean {
    if (!localStorage.getItem("user")) {
      this.router.navigate(['login']);
    }
    if (!this.auth.isAuthenticated()) {
      this.router.navigate(['login']);
      return false;
    }else{    
      if(!this.auth.isActivated()){
        this.router.navigate(['activate']);
      }
    }
    return true;
  }
}
