import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { DataBudget } from '../models/data-budget';
import { DataTarget } from '../models/data-target';
import { DataVideo } from '../models/data-video';
import { NewAdService } from '../services/new-ad/new-ad.service';

@Injectable({
  providedIn: 'root'
})
export class NewAdGuard  {
  activateRoute: string;
  dataVideo:DataVideo = null;
  dataTarget:DataTarget = null;
  dataBudget:DataBudget = null;
  constructor(public newAdService:NewAdService,public router: Router) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      this.dataVideo = this.newAdService.getDataVideo();
      this.dataTarget = this.newAdService.getDataTarget();
      this.dataBudget = this.newAdService.getDataBudget();
      this.activateRoute = this.getResolvedUrl(route);
      let file = this.newAdService.getVideo();
      console.log('guard',this.activateRoute);
      switch (this.activateRoute) {
        case 'new-ad-step-one':
          return true;
          break;
        case 'new-ad-step-two':
          if(this.dataVideo == null){
            this.router.navigate(['new-ad-step-one']);
          }
          return true;
          break;
        case 'new-ad-step-three':
          if(this.dataTarget == null || this.dataTarget.adName==null){
            this.router.navigate(['new-ad-step-two']);
          }
          return true;
          break;
        case 'new-ad-step-four':
          if(this.dataBudget == null || this.dataBudget.totalAmount==null){
            this.router.navigate(['new-ad-step-three']);
          }
          return true;
          break;
        default:
          return true;
          break;
      }

  }
  getResolvedUrl(route: ActivatedRouteSnapshot): string {
    return route.pathFromRoot
      .map(v => v.url.map(segment => segment.toString()))
      .join('');
  }
}
