import { Routes } from "@angular/router";
import { NewAdGuard } from "./dashboard/ads/guards/new-ad.guard";
import { AuthGuardService } from "./login/guards/auth-guard.service";
import { NotFoundComponent } from "./shared/components/not-found.component";
import { InjectionToken } from "@angular/core";
import { SHOWCASE_ROUTES } from "./shared/components/atomic-design/show-case.routes";
import { CREATE_AD_ROUTES } from "./dashboard/create-ad/create-ad.routes";
import { SHOP_ROUTES } from "./dashboard/shop/shop.routes";
const externalUrlProvider = new InjectionToken('externalUrlRedirectResolver');

export const APP_ROUTES:Routes = [
    {
        path: 'home',
        loadComponent: () => import('./dashboard/home/home.component').then((x) => x.HomeComponent)
    },
    {
        path: 'catalog',
        loadComponent: () =>
            import('./catalogs/pages/dashboard-home/dashboard-home.component').then((x) => x.DashboardHomeComponent),
        canActivate: [AuthGuardService]
    },
    {
        path: 'meatspace-catalog',
        loadComponent: () => import('./catalogs/pages/home/home.component').then((x) => x.HomeComponent),
        canActivate: []
    },
    {
        path: 'login',
        loadComponent: () => import('./login/components/main/main.component').then((x) => x.MainComponent)
    },
    {
        path: 'signup',
        loadComponent: () => import('./login/components/signin/signin.component').then((x) => x.SigninComponent)
    },
    {
        path: 'change-password',
        loadComponent: () => import('./login/components/change-password/change-password.component').then((x) => x.ChangePasswordComponent)
    },
    {
        path: 'activate',
        loadComponent: () => import('./login/components/activate/activate.component').then((x) => x.ActivateComponent)
    },
    {
        path: '',
        loadComponent: () => import('./dashboard/panel/panel.component').then((x) => x.PanelComponent),
        canActivate: [AuthGuardService]
    },
    {
        path: 'panel',
        loadComponent: () => import('./dashboard/panel/panel.component').then((x) => x.PanelComponent),
        canActivate: [AuthGuardService]
    },
    {
        path: 'profile',
        loadComponent: () => import('./dashboard/profile/profile.component').then((x) => x.ProfileComponent),
        canActivate: [AuthGuardService]
    },
    {
        path: 'user-info',
        loadComponent: () => import('./dashboard/profile/user-info/user-info.component').then((x) => x.UserInfoComponent),
        canActivate: [AuthGuardService]
    },
    {
        path: 'externalRedirect',
        canActivate: [externalUrlProvider],
        component: NotFoundComponent
    },
    {
        path: 'ads',
        loadComponent: () => import('./dashboard/ads/pages/list-ads/list-ads.component').then((x) => x.ListAdsComponent),
        canActivate: [AuthGuardService]
    },
    {
        path: 'ad/:id',
        loadComponent: () => import('./dashboard/ads/pages/detail-ad/detail-ad.component').then((x) => x.DetailAdComponent),
        canActivate: [AuthGuardService]
    },
    {
        path: 'ad/edit/:id',
        loadComponent: () => import('./dashboard/ads/pages/edit-ad/edit-ad.component').then((x) => x.EditAdComponent),
        canActivate: [AuthGuardService]
    },
    {
        path: 'ad/:id/report',
        loadComponent: () => import('./dashboard/ads/pages/detail-ad/detail-ad.component').then((x) => x.DetailAdComponent),
        canActivate: [AuthGuardService]
    },
    {
        path: 'new-ad-step-one',
        loadComponent: () =>
            import('./dashboard/ads/pages/new-ad-step-one/new-ad-step-one.component').then((x) => x.NewAdStepOneComponent),
        canActivate: [AuthGuardService, NewAdGuard]
    },
    {
        path: 'new-ad-step-two',
        loadComponent: () =>
            import('./dashboard/ads/pages/new-ad-step-two/new-ad-step-two.component').then((x) => x.NewAdStepTwoComponent),
        canActivate: [AuthGuardService, NewAdGuard]
    },
    {
        path: 'new-ad-step-three',
        loadComponent: () =>
            import('./dashboard/ads/pages/new-ad-step-three/new-ad-step-three.component').then((x) => x.NewAdStepThreeComponent),
        canActivate: [AuthGuardService, NewAdGuard]
    },
    {
        path: 'new-ad-step-four',
        loadComponent: () =>
            import('./dashboard/ads/pages/new-ad-step-four/new-ad-step-four.component').then((x) => x.NewAdStepFourComponent),
        canActivate: [AuthGuardService, NewAdGuard]
    },
    {
        path: 'recharge-ad/:id',
        loadComponent: () => import('./dashboard/ads/pages/recharge-ad/recharge-ad.component').then((x) => x.RechargeAdComponent),
        canActivate: [AuthGuardService]
    },
    {
        path: 'incomings',
        loadComponent: () => import('./incomings/pages/list-incomings/list-incomings.component').then((x) => x.ListIncomingsComponent),
        canActivate: []
    },
    {
        path: 'payer',
        loadComponent: () => import('./payments/pages/step1-payer/step1-payer.component').then((x) => x.Step1PayerComponent),
        canActivate: [AuthGuardService]
    },
    {
        path: 'payment',
        loadComponent: () => import('./payments/pages/step2-payment/step2-payment.component').then((x) => x.Step2PaymentComponent),
        canActivate: [AuthGuardService]
    },
    {
        path: 'new-client',
        loadComponent: () => import('./payments/pages/new-client/new-client.component').then((x) => x.NewClientComponent),
        canActivate: [AuthGuardService]
    },
    {
        path: 'payment-methods',
        loadComponent: () => import('./payments/pages/payment-methods/payment-methods.component').then((x) => x.PaymentMethodsComponent),
        canActivate: [AuthGuardService]
    },
    {
        path: 'edit-payer',
        loadComponent: () => import('./payments/pages/edit-payer/edit-payer.component').then((x) => x.EditPayerComponent),
        canActivate: [AuthGuardService]
    },
    {
        path: 'my-referrals',
        loadComponent: () => import('./referings/pages/user-referings/user-referings.component').then((x) => x.UserReferingsComponent),
        canActivate: []
    },
    {
        path: 'refered/:code',
        loadComponent: () => import('./referings/pages/refered-redirect/refered-redirect.component').then((x) => x.ReferedRedirectComponent),
        canActivate: []
    },
    {
        path: 'bullet',
        loadComponent: () => import('./dashboard/bullet/pages/main-bullet/main-bullet.component').then((x) => x.MainBulletComponent),
        canActivate: [AuthGuardService]
    },
    {
        path: 'screens',
        loadComponent: () => import('./dashboard/screens/pages/list-screens/list-screens.component').then((x) => x.ListScreensComponent),
        canActivate: [AuthGuardService]
    },
    {
        path: 'screen/:id',
        loadComponent: () => import('./dashboard/screens/pages/detail-screen/detail-screen.component').then((x) => x.DetailScreenComponent),
        canActivate: [AuthGuardService]
    },
    {
        path: 'screen/new-default-ad/:id',
        loadComponent: () => import('./dashboard/screens/pages/new-default-video/new-default-video.component').then((x) => x.NewDefaultVideoComponent),
        canActivate: [AuthGuardService]
    },
    {
        path: 'screen/new-default-ad/:id/step-two',
        loadComponent: () => import('./dashboard/screens/pages/new-default-video-two/new-default-video-two.component').then((x) => x.NewDefaultVideoTwoComponent),
        canActivate: [AuthGuardService]
    },
    {
        path: 'screen/new-default-ad/:id/step-three',
        loadComponent: () => import('./dashboard/screens/pages/new-default-video-three/new-default-video-three.component').then((x) => x.NewDefaultVideoThreeComponent),
        canActivate: [AuthGuardService]
    },
    ...CREATE_AD_ROUTES,
    ...SHOWCASE_ROUTES,
    ...SHOP_ROUTES
];