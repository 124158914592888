import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-secondary-title',
  templateUrl: './secondary-title.component.html',
  styleUrls: ['./secondary-title.component.scss'],
  standalone: true
})
export class SecondaryTitleComponent implements OnInit {
  @Input() class = "";
  constructor() { }

  ngOnInit() {
  }
}
