import { REDIRECTION_PATHS } from "./paths/redirection-paths";
import { MARKETPLACE_PATHS } from "./paths/marketplace-paths";
import { MARKETPLACE_USERSCREENS_PATHS } from "./paths/marketplace-userscreens-paths";
import { SAMPLING_PATHS } from "./paths/sampling-paths";
import { BASIC_USERSCREENS_PATHS } from "./paths/basic-userscreens-paths";

export const CREATE_AD_PATHS = {
    'user-network': {
        ...BASIC_USERSCREENS_PATHS,
        ...MARKETPLACE_USERSCREENS_PATHS,
        ...SAMPLING_PATHS
    },
    'all-network': {
        ...REDIRECTION_PATHS,
        ...MARKETPLACE_PATHS,
    }
}