<div class="generic-error-modal">
  <app-modal-header title="Algo Salio Mal">
    <div end (click)="goBack()">x</div>
  </app-modal-header>
  <div class="modal-cont">
    <app-secondary-title>{{errorForHuman[data.status].label}} - {{data.status}}</app-secondary-title>
    <app-body-text>{{errorForHuman[data.status].description}}</app-body-text>
    <img src="/assets/svgs/error.svg" class="w-75 m-auto" alt="">
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Mostrar mensaje del sistema
        </mat-panel-title>
      </mat-expansion-panel-header>
      <app-body-text>{{data | json}}</app-body-text>
    </mat-expansion-panel>
    <div class="mt-3">
      <app-secondary-button (click)="continue(true)">Aceptar</app-secondary-button>
    </div>
  </div>
</div>
