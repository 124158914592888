import { Routes } from "@angular/router";
import { AuthGuardService } from "src/app/login/guards/auth-guard.service";
import { CreateAdMarketplaceGuard } from "./guards/create-ad-marketplace.guard";

export const CREATE_AD_ROUTES: Routes = [
    {
        path: 'create-ad/how-works',
        loadComponent: () => import('./pages/how-works/how-works.component').then((x) => x.HowWorksComponent),
        canActivate: [CreateAdMarketplaceGuard]
    },
    {
        path: 'create-ad/network-selection',
        loadComponent: () => import('./pages/network-selection/network-selection.component').then((x) => x.NetworkSelectionComponent),
        canActivate: [CreateAdMarketplaceGuard]
    },
    {
        path: 'create-ad/campaign-type',
        loadComponent: () => import('./pages/campaign-type/campaign-type.component').then((x) => x.CampaignTypeComponent),
        canActivate: [CreateAdMarketplaceGuard]
    },
    // {
    //     path: 'create-ad/advertiser-info',
    //     loadComponent: () => import('./pages/advertiser-info/advertiser-info.component').then((x) => x.AdvertiserInfoComponent),
    //     canActivate: [CreateAdMarketplaceGuard]
    // },
    {
        path: 'create-ad/upload-video',
        loadComponent: () => import('./pages/upload-video/upload-video.component').then((x) => x.UploadVideoComponent),
        canActivate: [CreateAdMarketplaceGuard]
    },
    {
        path: 'create-ad/ad-title',
        loadComponent: () => import('./pages/ad-title/ad-title.component').then((x) => x.AdTitleComponent),
        canActivate: [CreateAdMarketplaceGuard]
    },
    {
        path: 'create-ad/details',
        loadComponent: () => import('./pages/ad-info/ad-info.component').then((x) => x.AdInfoComponent),
        canActivate: [CreateAdMarketplaceGuard]
    },
    {
        path: 'create-ad/marketplace',
        loadComponent: () => import('./pages/marketplace/marketplace.component').then((x) => x.MarketplaceComponent),
        canActivate: [CreateAdMarketplaceGuard]
    },
    {
        path: 'create-ad/marketplace/price',
        loadComponent: () => import('./pages/marketplace-price/marketplace-price.component').then((x) => x.MarketplacePriceComponent),
        canActivate: [CreateAdMarketplaceGuard]
    },
    {
        path: 'create-ad/marketplace/images',
        loadComponent: () => import('./pages/marketplace-images/marketplace-images.component').then((x) => x.MarketplaceImagesComponent),
        canActivate: [CreateAdMarketplaceGuard]
    },
    {
        path: 'create-ad/marketplace/details',
        loadComponent: () => import('./pages/marketplace-details/marketplace-details.component').then((x) => x.MarketplaceDetailsComponent),
        canActivate: [CreateAdMarketplaceGuard]
    },
    {
        path: 'create-ad/marketplace/locations',
        loadComponent: () => import('./pages/marketplace-locations/marketplace-locations.component').then((x) => x.MarketplaceLocationsComponent),
        canActivate: [CreateAdMarketplaceGuard]
    },
    {
        path: 'create-ad/budget',
        loadComponent: () => import('./pages/budget/budget.component').then((x) => x.BudgetComponent),
        canActivate: [CreateAdMarketplaceGuard]
    },
    {
        path: 'create-ad/zones',
        loadComponent: () => import('./pages/zones/zones.component').then((x) => x.ZonesComponent),
        canActivate: [CreateAdMarketplaceGuard]
    },
    {
        path: 'create-ad/target',
        loadComponent: () => import('./pages/target/target.component').then((x) => x.TargetComponent),
        canActivate: [CreateAdMarketplaceGuard]
    },
    {
        path: 'create-ad/summary',
        loadComponent: () => import('./pages/summary/summary.component').then((x) => x.SummaryComponent),
        canActivate: [CreateAdMarketplaceGuard]
    },
    {
        path: 'create-ad/contract',
        loadComponent: () => import('./pages/contract/contract.component').then((x) => x.ContractComponent),
        canActivate: [AuthGuardService,CreateAdMarketplaceGuard]
    },
    {
        path: 'create-ad/payment',
        loadComponent: () => import('./pages/payment/payment.component').then((x) => x.PaymentComponent),
        canActivate: [AuthGuardService,CreateAdMarketplaceGuard]
    },
    {
        path: 'create-ad/screens-selection',
        loadComponent: () => import('./pages/screens-selection/screens-selection.component').then((x) => x.ScreensSelectionComponent),
        canActivate: [AuthGuardService,CreateAdMarketplaceGuard]
    },
    {
        path: 'create-ad/sampling/product',
        loadComponent: () => import('./pages/sampling-product/sampling-product.component').then((x) => x.SamplingProductComponent),
        canActivate: [CreateAdMarketplaceGuard]
    },
    {
        path: 'create-ad/sampling/stock',
        loadComponent: () => import('./pages/sampling-stock/sampling-stock.component').then((x) => x.SamplingStockComponent),
        canActivate: [CreateAdMarketplaceGuard]
    },
    {
        path: 'create-ad/sampling/details',
        loadComponent: () => import('./pages/sampling-details/sampling-details.component').then((x) => x.SamplingDetailsComponent),
        canActivate: [CreateAdMarketplaceGuard]
    },
    {
        path: 'create-ad/publication-dates',
        loadComponent: () => import('./pages/publication-dates/publication-dates.component').then((x) => x.PublicationDatesComponent),
        canActivate: [CreateAdMarketplaceGuard]
    },    
    {
        path: 'create-ad/publication-dates-with-days',
        loadComponent: () => import('./pages/publication-dates-with-days/publication-dates-with-days.component').then((x) => x.PublicationDatesWithDaysComponent),
        canActivate: [CreateAdMarketplaceGuard]
    },  
    {
        path: 'create-ad/summary-and-create',
        loadComponent: () => import('./pages/summary-and-create/summary-and-create.component').then((x) => x.SummaryAndCreateComponent),
        canActivate: [CreateAdMarketplaceGuard]
    },
];