import { enableProdMode, importProvidersFrom } from '@angular/core';

import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { provideRouter } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { withInterceptorsFromDi, provideHttpClient, HttpClient } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { provideAnimations } from '@angular/platform-browser/animations';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { MaterialModule } from './app/material/material.module';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { JWT_OPTIONS, JwtHelperService, JwtModule } from '@auth0/angular-jwt';
import { CURRENCY_MASK_CONFIG, CurrencyMaskConfig } from 'ng2-currency-mask';
import { GoogleLoginProvider, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
import { NgxStripeModule } from 'ngx-stripe';
import { APP_ROUTES } from './app/app.routes';

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(http);
}

export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
    align: "left",
    allowNegative: false,
    decimal: ".",
    precision: 0,
    prefix: "€ ",
    suffix: "",
    thousands: ","
};

if (environment.production) {
    enableProdMode();
}

bootstrapApplication(AppComponent, {
    providers: [
        {
            provide: "SocialAuthServiceConfig",
            useValue: {
                autoLogin: true,
                providers: [
                    {
                        id: GoogleLoginProvider.PROVIDER_ID,
                        provider: new GoogleLoginProvider('355600503236-da4gnb8dbt1iq8ns9bh3orrogdje85f9.apps.googleusercontent.com')
                    }
                ]
            } as SocialAuthServiceConfig
        },
        importProvidersFrom(NgxStripeModule.forRoot(environment.stripe_pk), JwtModule, MaterialModule, BrowserModule, ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }), TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }), FormsModule, ReactiveFormsModule),
        { provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig },
        { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
        JwtHelperService, Location, { provide: LocationStrategy, useClass: PathLocationStrategy, },
        provideAnimations(),
        provideHttpClient(withInterceptorsFromDi()),
        provideRouter([
            ...APP_ROUTES
        ])
    ]
})
    .catch(err => console.error(err));
