import { Routes } from '@angular/router';
import { AuthGuardService } from 'src/app/login/guards/auth-guard.service';

export const SHOP_ROUTES: Routes = [
    {
        path: 'my-shop',
        loadComponent: () => import('./pages/my-shop/my-shop.component').then((x) => x.MyShopComponent),
        canActivate: [AuthGuardService]
    },
    {
        path: 'product-stock-used',
        loadComponent: () => import('./pages/product-stock-used/product-stock-used.component').then((x) => x.ProductStockUsedComponent),
        canActivate: [AuthGuardService]
    }   
];