import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NgClass, NgIf } from '@angular/common';
import { NavWalletComponent } from 'src/app/dashboard/bullet/components/nav-wallet/nav-wallet.component';
import { UserService } from 'src/app/login/services/user/user.service';
import { ModalTriggerService } from '../../services/modal-trigger.service';

@Component({
  selector: 'app-menu-dasboard',
  templateUrl: './menu-dasboard.component.html',
  styleUrls: ['./menu-dasboard.component.scss'],
  standalone: true,
  imports: [NgClass, NgIf, RouterLink, TranslateModule, NavWalletComponent]
})
export class MenuDashboardComponent implements OnInit {
  url = '/';
  menuClose = false;
  mobileHide = false;
  @Output() toggleEvent = new EventEmitter<boolean>();
  constructor(public auth: UserService,
    private router: Router, private modalService: ModalTriggerService) {

    this.router.events.subscribe((path: any) => {
      if (path.url) {
        this.url = path.url;
      }
      if (this.url == "/new-ad-step-one" || this.url == "/new-ad-step-two" || this.url == "/new-ad-step-three" || this.url == "/new-ad-step-four" || this.url.includes("/ad/")) {
        this.mobileHide = true;
      } else {
        this.mobileHide = false;
      }

    });
  }

  ngOnInit(): void {

  }

  toggleMenu(): void {
    this.menuClose = !this.menuClose;
    this.toggleEvent.emit(this.menuClose);
  }

  closeSession() {
    const modalData = {
      title: 'Quieres cerrar session ?',
      body: 'al cerrar session perderas datos locales como campañas en proceso de creacion sin finalizar.'
    }
    this.modalService.showConfirmation(modalData, (result) => {
      if (result == true) {
        this.auth.closeSession();
      }
    })

  }

}
