import { LocalStorageModel } from '../../models/LocalStorageModel';
import { catchError, map } from 'rxjs/operators';
import { User, LoginResponse, UserData, RememberPasswordResponse, ChangePasswordResponse } from '../../models/user.interface';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';
import { Payer } from 'src/app/payments/models/payer.interface';
import { environment } from 'src/environments/environment';
import { SocialAuthService, SocialUser } from '@abacritt/angularx-social-login';
import { GenericErrorModalComponent } from 'src/app/shared/components/atomic-design/molecules/generic-error-modal/generic-error-modal.component';
import { MatDialog } from '@angular/material/dialog';
const helper = new JwtHelperService();

@Injectable({
  providedIn: 'root'
})
export class UserService {
  API_URL = environment.apiUrl;
  endpointLogin = this.API_URL + 'auth/login';
  endpointSignIn = this.API_URL + 'user';
  endpointGetMyUser = this.API_URL + 'user/oneUser';
  endpointRememberPass = this.API_URL + 'user/rememberPassword';
  endpointChangePass = this.API_URL + 'user/changePassword';
  endpointGoogleAuth = this.API_URL + 'auth/googleAuth';
  endpointGetUserGeneratedMoney = this.API_URL + 'user/generatedMoney';
  endpointReSendVerify = this.API_URL + 'user/reSendVerify';
  endpointCompleteUser = this.API_URL + 'user/completeUser';
  endpointChangePasswordWithConfirmationCode = this.API_URL + 'user/changePasswordWithConfirmationCode';
  endpointCheckConfirmationCode = this.API_URL + 'user/checkConfirmationCode';
  endpointEditSpacerProfile = this.API_URL + 'user/editSpacerProfile';
  headers = new HttpHeaders({
    'Content-Type': 'application/json'
  });
  endpointGetUserById= this.API_URL + 'user/getUserById';
  endpointUploadSpacerLogo = this.API_URL + 'user/uploadSpacerLogo';
  endpointUploadSpacerBanner = this.API_URL + 'user/uploadSpacerBanner';
  constructor(private http: HttpClient, public jwtHelper: JwtHelperService, public router: Router, private googleAuthService: SocialAuthService, private dialog:MatDialog) {
  }

  login(user: User): Observable<LoginResponse> {
    const body = {
      username: user.username,
      password: user.password
    };
    let options = {};
    options = { headers: this.headers };
    return this.http.post<LoginResponse>(this.endpointLogin, body, options)
      .pipe(
        catchError((error) => this.errorMgmt(error))
      );
  }
  signIn(user: User): Observable<LoginResponse> {
    const body = {
      username: user.username,
      email: user.email,
      password: user.password,
      user_phone_code: user.user_phone_code,
      user_phone: user.user_phone,
      user_name: user.user_name.split(" ")[0],
      user_surname: user.user_name.split(" ")[1],
      user_company: user.user_company,
      user_intention: user.user_intention,
      commercial_consent: user.commercial_consent,
      referedCode: localStorage.getItem('referedCode')
    };
    let options = {};
    options = { headers: this.headers };
    return this.http.post<LoginResponse>(this.endpointSignIn, body, options).pipe(
      catchError((error) => this.errorMgmt(error))
    );
  }
  googleAuth(user: SocialUser): Observable<any> {
    let options = {};
    options = { headers: this.headers };
    const dataUser = {...user,referedCode: localStorage.getItem('referedCode')}
    return this.http.post<any>(this.endpointGoogleAuth, dataUser, options)
      .pipe(
        catchError((error) => this.errorMgmt(error))
      );
  }
  setLocalData(userData: LocalStorageModel) {
    localStorage.setItem("user", JSON.stringify(userData));
  }
  setPayerData(userData: Payer) {
    localStorage.setItem("payer", JSON.stringify(userData));
  }
  getPayerData() {
    return JSON.parse(localStorage.getItem("payer"));
  }
  getToken() {
    const local = JSON.parse(localStorage.getItem("user"));
    return local.token;
  }
  getUser() {
    const local = JSON.parse(localStorage.getItem("user"));
    return local;
  }
  getUserData(): Observable<UserData> {
    let options = {};
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'auth': this.getToken()
    });
    options = { headers: headers };
    return this.http.get<UserData>(this.endpointGetMyUser, options).pipe(
      catchError((error) => this.errorMgmt(error))
    );
  }
  getUserGeneratedMoney(): Observable<any> {
    let options = {};
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'auth': this.getToken()
    });
    options = { headers: headers };
    return this.http.get<any>(this.endpointGetUserGeneratedMoney, options).pipe(
      catchError((error) => this.errorMgmt(error))
    );
  }
  closeSession() {
    localStorage.clear();
    this.googleAuthService.authState.subscribe(async user => {
      console.log(user);
      if (user != null) {
        await this.googleAuthService.signOut();
      }
    });
    this.router.navigate(['login']);
  }
  rememberPassword(data): Observable<RememberPasswordResponse> {
    let options = {};
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const body = {
      username: data.username
    };
    options = { headers: headers };
    return this.http.post<RememberPasswordResponse>(this.endpointRememberPass, body, options).pipe(
      catchError((error) => this.errorMgmt(error))
    );
  }
  changePasswordWithConfirmationCode(data): Observable<any> {
    let options = {};
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const body = {
      confirmationCode: data.confirmationCode,
      password: data.password,
    };
    options = { headers: headers };
    return this.http.post<any>(this.endpointChangePasswordWithConfirmationCode, body, options).pipe(
      catchError((error) => this.errorMgmt(error))
    );
  }
  changePassword(data): Observable<ChangePasswordResponse> {
    let options = {};
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'auth': this.getToken()
    });
    const body = {
      password: data.password
    };
    options = { headers: headers };
    return this.http.post<ChangePasswordResponse>(this.endpointChangePass, body, options).pipe(
      catchError((error) => this.errorMgmt(error))
    );
  }
  checkConfirmationCode(data): Observable<any> {
    let options = {};
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const body = {
      confirmationCode: data.confirmationCode
    };
    options = { headers: headers };
    return this.http.post<any>(this.endpointCheckConfirmationCode, body, options).pipe(
      catchError((error) => this.errorMgmt(error))
    );
  }
  completeUser(body) {
    let options = {};
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'auth': this.getToken()
    });
    options = { headers: headers };
    return this.http.post<ChangePasswordResponse>(this.endpointCompleteUser, body, options).pipe(
      catchError((error) => this.errorMgmt(error))
    );
  }
  public isAuthenticated(): boolean {
    const token = this.getToken();
    return !this.jwtHelper.isTokenExpired(token);
  }
  public isActivated() {
    return this.getUser().is_verified;
  }
  public reSendVerify() {
    let options = {};
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'auth': this.getToken()
    });
    const body = {
    };
    options = { headers: headers };
    return this.http.post<any>(this.endpointReSendVerify, body, options).pipe(
      catchError((error) => this.errorMgmt(error))
    );
  }

  editSpacerProfile(userData): Observable<any> {
    let options = {};
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'auth': this.getToken()
    });
    const body = {
      ...userData
    };
    options = { headers: headers };
    return this.http.post<any>(this.endpointEditSpacerProfile, body, options)
      .pipe(
        catchError((error) => this.errorMgmt(error))
      );
  }

  uploadSpacerLogo(file): Observable<any> {
    var blob = this.dataURItoBlob(file);
    let formData = new FormData();
    const user = this.getUser();
    const imageName = 'advertiser_'+user?.id;
    formData.append("file", blob, imageName+'.png');
    formData.append("data", JSON.stringify({imageName: imageName + '.png'}));
    let options = {};

    const headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
      'auth': this.getToken()
    });
    options = { headers: headers };
    return this.http.post<any>(this.endpointUploadSpacerLogo, formData, options)
      .pipe(
        catchError((error) => this.errorMgmt(error))
      );
  }

  uploadSpacerBanner(file): Observable<any> {
    var blob = this.dataURItoBlob(file);
    let formData = new FormData();
    const user = this.getUser();
    const imageName = 'advertiser_banner_'+user?.id;
    formData.append("file", blob, imageName+'.png');
    formData.append("data", JSON.stringify({imageName: imageName + '.png'}));
    let options = {};

    const headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
      'auth': this.getToken()
    });
    options = { headers: headers };
    return this.http.post<any>(this.endpointUploadSpacerBanner, formData, options)
      .pipe(
        catchError((error) => this.errorMgmt(error))
      );
  }

  getUserFromToken(): Observable<any> {
    let options = {};
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'auth': this.getToken()
    });
    options = { headers: headers };
    return this.http.get<UserData>(this.endpointGetUserById, options).pipe(
      catchError((error) => this.errorMgmt(error))
    );
  }
  
  errorMgmt(error: HttpErrorResponse) {
    let errorMessage = error.error.message;
    console.log(`Error Code: ${error.status}\nMessage: ${error.message}`);
    console.log(errorMessage);
    if (!this.dialog.getDialogById('genericErrorDialog')) {
      const dialogRef = this.dialog.open(GenericErrorModalComponent, {
        id: 'genericErrorDialog',
        width: '500px',
        data: error
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
  
        }
      });
    }
    return throwError(error.message);
  }
  dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(dataURI.split(',')[1]);
    else
        byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], {type:mimeString});
  }
}
