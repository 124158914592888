import { CampaignDataInterface } from "../../ads/models/campaignData.interface";

export const MARKETPLACE_USERSCREENS_PATHS = {
    'marketplace': {
        'paths': {
            'A': {
                'create-ad/screens-selection': {
                    title: 'Pantallas',
                    icon: 'crop_portrait',
                    step: 1,
                    back: 'create-ad/campaign-type',
                    next: 'create-ad/upload-video',
                    validate: function (campaignData: CampaignDataInterface) {
                        return !campaignData.campaignType || !campaignData.pathVariation;
                    }
                },
                'create-ad/upload-video': {
                    title: 'Vídeo',
                    icon: 'videocam',
                    step: 2,
                    back: 'create-ad/screens-selection',
                    next: 'create-ad/marketplace',
                    validate: function (campaignData: CampaignDataInterface) {
                        return !campaignData.screensSelected;
                    }
                },
                'create-ad/marketplace': {
                    title: 'Título',
                    icon: 'local_offer',
                    step: 3,
                    back: 'create-ad/upload-video',
                    next: 'create-ad/marketplace/price',
                    validate: function (campaignData: CampaignDataInterface) {
                        return !campaignData.dataVideo;
                    }
                },
                'create-ad/marketplace/price': {
                    title: 'Detalles',
                    icon: 'plagiarism',
                    step: 4,
                    back: 'create-ad/marketplace',
                    next: 'create-ad/marketplace/images',
                    validate: function (campaignData: CampaignDataInterface) {
                        return !campaignData.dataProduct?.title;
                    }
                },
                'create-ad/marketplace/images': {
                    title: 'Imágenes',
                    icon: 'image',
                    step: 5,
                    back: 'create-ad/marketplace/price',
                    next: 'create-ad/marketplace/details',
                    validate: function (campaignData: CampaignDataInterface) {
                        return !campaignData.dataProduct?.price && !campaignData.dataProduct?.unitsForClient;
                    }
                },
                'create-ad/marketplace/details': {
                    title: 'Descripción',
                    icon: 'create',
                    step: 6,
                    back: 'create-ad/marketplace/images',
                    next: 'create-ad/marketplace/locations',
                    validate: function (campaignData: CampaignDataInterface) {
                        return !campaignData.dataProduct?.images;
                    }
                },
                'create-ad/marketplace/locations': {
                    title: 'Sucursales',
                    icon: 'store_mall_directory',
                    step: 7,
                    back: 'create-ad/marketplace/details',
                    next: 'create-ad/publication-dates-with-days',
                    validate: function (campaignData: CampaignDataInterface) {
                        return !campaignData.dataProduct?.description;
                    }
                },
                'create-ad/publication-dates-with-days': {
                    title: 'Programación',
                    icon: 'today',
                    step: 8,
                    back: 'create-ad/marketplace/locations',
                    next: 'create-ad/target',
                    validate: function (campaignData: CampaignDataInterface) {
                        return;
                    }
                },
                'create-ad/target': {
                    title: 'Público',
                    icon: 'people_alt',
                    step: 9,
                    back: 'create-ad/publication-dates-with-days',
                    next: 'create-ad/summary-and-create',
                    validate: function (campaignData: CampaignDataInterface) {
                        return;
                    }
                },
                'create-ad/summary-and-create': {
                    title: 'Resumen',
                    icon: 'description',
                    step: 10,
                    back: 'create-ad/target',
                    next: 'create-ad/contract',
                    validate: function (campaignData: CampaignDataInterface) {
                        return;
                    }
                }
            }
        },
        'benefits': [
            {
                icon: 'watch_later',
                name: 'Tiempo de publicación',
                items: [
                    'Requiere aprobación'
                ]
            },
            {
                icon: 'settings',
                name: 'Gestión del anuncio',
                items: [
                    'Selección de pantallas',
                    'Fecha de inicio y fin',
                    'Configuración de franja horaria',
                    'Selección de público objetivo según su sexo y edad',
                    'Elige el tipo de promoción: descuentos (50%) u ofertas (2x1)'
                ]
            },
            {
                icon: 'trending_up',
                name: 'Datos en tiempo real de tu anuncio',
                items: [
                    'Número de personas alcanzadas',
                    'Número de visualizaciones',
                    'Tiempo de visualización promedio',
                    'Sexo y edad de las personas que visualizaron',
                    'Rendimiento por fecha y hora',
                    'Alcance del anuncio en la App',
                    'Número de clics en el anuncio',
                    'Número de promociones canjeadas',
                    'Tasa de conversión',
                    'Coste por contacto captado'
                ]
            },
            {
                icon: 'phone_android',
                name: 'Meatspace App',
                items: [
                    'El anuncio con la promoción se mostrará en tu perfil y en el feed de la App de Meatspace, donde todos los usuarios registrados lo podrán ver mientras dure la campaña',
                    'La promoción se podrá canjear a través de la App, mientras dure la campaña',
                    'Obtendrás los datos de contacto verificados de los clientes que canjean la promoción',
                    'Comisión por promoción canjeada 0%.'
                ]
            },
            {
                icon: 'group',
                name: 'CRM',
                items: [
                    'Gestión de contactos',
                    'Seguimiento de la interacción con el cliente en cada etapa del ciclo de venta',
                    'Mensajes transaccionales con los contactos por What’sApp Business',
                    'Mensajes transaccionales con los contactos por Email',
                    'Comisión por nuevo lead 0,37€'
                ]
            }
        ],
    }
}