import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-body-text',
  templateUrl: './body-text.component.html',
  styleUrls: ['./body-text.component.scss'],
  standalone: true
})
export class BodyTextComponent implements OnInit {
  @Input() class = "";
  @Input() small = false;
  @Input() big = false;
  @Input() jumbo = false;
  @Input() italic = false;
  @Input() gray = false;
  @Input() darkGray = false;
  @Input() white = false;
  constructor() { }

  ngOnInit() {
  }

}
