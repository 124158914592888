import { Component, Input, OnInit } from '@angular/core';
import { NgIf } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss'],
    standalone: true,
    imports: [NgIf, MatProgressSpinnerModule]
})
export class LoaderComponent implements OnInit {
  @Input()
  condition;
  constructor() { }

  ngOnInit(): void {
  }

}
