<div class="d-flex">
    <span *ngIf="isDev" style="    
    position: absolute;
    top: 7px;
    right: -40px;
    background-color: red;
    color: white;
    padding: 2px 46px;
    z-index: 99999999;
    transform: rotate(45deg);
    font-size: 12px;
    font-weight: bolder;
    ">DEV</span>
    <app-header *ngIf="!isLogin && loading==false" [menuClose]="menuClose">
    </app-header>
    <app-menu-dasboard *ngIf="!isLogin" (toggleEvent)="menuClose = $event"></app-menu-dasboard>
    <div id="app-container" [ngClass]="{'login' : isLogin}">
        <app-loader [condition]="loading==true"></app-loader>
        <router-outlet *ngIf="loading==false"></router-outlet>
    </div>
</div>
<!-- <app-tutorial-launcher></app-tutorial-launcher> -->
<div class="centered-tour-element" tourAnchor="start-tour"></div>
<!-- <tour-step-template></tour-step-template> -->
<app-footer></app-footer>