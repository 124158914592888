<header class="bg-primary-deg" *ngIf="!isLogin" [ngClass]="{'collapsed' : menuClose}">
    <div class="navbar-logo">
        <img [routerLink]="['/']" class="logo" src="../../../../assets/images/logo_white.png">
    </div>
    <!-- <div class="d-flex">
        <app-nav-wallet tourAnchor="wallet"></app-nav-wallet>
        <div class="profileDesktop" tourAnchor="userMenu">
            <button mat-button [matMenuTriggerFor]="menu">
                <img class="avatar-image" src="../../../../assets/images/svg/avatar.svg">{{ userTitleMenu }}
                <span class="material-icons">
                    arrow_drop_down
                </span>
            </button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item [routerLink]="['profile']">{{'NAV.MENU.PROFILE' | translate }}</button>
                <button mat-menu-item [routerLink]="['my-referrals']">{{'NAV.MENU.REFERRALS' | translate }}</button>
                <button mat-menu-item (click)="closeSession()">{{'NAV.MENU.LOGOUT' | translate }}</button>
            </mat-menu>
        </div>
    </div> -->
</header>