<div class="dashBoardMenu" [ngClass]="{'collapsed' : menuClose, 'mobileHide' : mobileHide}">
    <div class="menu-cont" tourAnchor="menu">
        <div class="optionMenu menu-close" (click)="toggleMenu()">
            <span class="material-icons" *ngIf="!menuClose">
                close
            </span>
            <span class="material-icons" *ngIf="menuClose">
                menu
            </span>
            <div class="main-nav__link">{{'NAV.SIDEBAR.MENU' | translate }}</div>
        </div>
        <div class="optionMenu" [routerLink]="['']" [ngClass]="{'selected': url === '/'}">
            <span class="material-icons">
                dashboard
            </span>
            <div class="main-nav__link">{{'NAV.SIDEBAR.CONTROL_PANEL' | translate }}</div>
        </div>
        <div class="optionMenu" [routerLink]="['screens']" [ngClass]="{'selected': url === '/screens'}">
            <span class="material-icons">
                monitor
            </span>
            <div class="main-nav__link">{{'NAV.SIDEBAR.SCREENS' | translate }}</div>
        </div>
        <div class="optionMenu" [routerLink]="['my-shop']" [ngClass]="{'selected': url === '/my-shop'}">
            <span class="material-icons">
                store
            </span>
            <div class="main-nav__link">{{'NAV.SIDEBAR.STORE' | translate }}</div>
        </div>
        <div class="optionMenu" [routerLink]="['product-stock-used']" [ngClass]="{'selected': url === '/product-stock-used'}">
            <span class="material-icons">
                confirmation_number
            </span>
            <div class="main-nav__link">{{'NAV.SIDEBAR.STOCKUSED' | translate }}</div>
        </div>
        <div id="add-button" class="optionMenu" [routerLink]="['new-ad-step-one']"
            [ngClass]="{'selected': url === '/new-ad-step-one'}">
            <span class="material-icons">
                add
            </span>
            <div class="main-nav__link">{{'NAV.SIDEBAR.ADD_AD' | translate }}</div>
        </div>
        <div class="optionMenu" [ngClass]="{'selected': url === '/ads'}" [routerLink]="['/ads']">
            <span class="material-icons">
                video_library
            </span>
            <div class="main-nav__link">{{'NAV.SIDEBAR.CAMPAIGNS' | translate }}</div>
        </div>
        <hr>
        <div class="optionMenu" [ngClass]="{'selected': url === '/profile'}" [routerLink]="['/profile']">
            <span class="material-icons">
                person
            </span>
            <div class="main-nav__link">{{'NAV.MENU.PROFILE' | translate }}</div>
        </div>       
        <div class="optionMenu" (click)="closeSession()">
            <span class="material-icons">
                logout
            </span>
            <div class="main-nav__link">{{'NAV.MENU.LOGOUT' | translate }}</div>
        </div>
        <div class="optionMenu">
            <span class="material-icons">
                wallet
            </span>
            <div class="main-nav__link">
                <app-nav-wallet [noIcon]="true" tourAnchor="wallet"></app-nav-wallet>
            </div>
        </div> 
        
    </div>
</div>