import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { GenericErrorModalComponent } from '../components/atomic-design/molecules/generic-error-modal/generic-error-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { GenericImageModalComponent } from '../components/atomic-design/molecules/generic-image-modal/generic-image-modal.component';
import { ConfirmationModalComponent } from '../components/atomic-design/molecules/confirmation-modal/confirmation-modal.component';
import { ExampleModalComponent } from '../components/atomic-design/molecules/example-modal/example-modal.component';

@Injectable({
  providedIn: 'root'
})
export class ModalTriggerService {

  constructor(private dialog: MatDialog) { }

  showError(error: HttpErrorResponse, callback?: (result: any) => void) {
    if (!this.dialog.getDialogById('genericErrorDialog')) {
      const dialogRef = this.dialog.open(GenericErrorModalComponent, {
        id: 'genericErrorDialog',
        width: '500px',
        data: error
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          // Aquí puedes ejecutar cualquier lógica adicional que necesites
        }
        if (callback) {
          callback(result);
        }
      });
    }
  }

  showSuccess(data, callback?: (result: any) => void) {
    if (!this.dialog.getDialogById('genericSuccessDialog')) {
      const dialogRef = this.dialog.open(GenericImageModalComponent, {
        id: 'genericSuccessDialog',
        width: '500px',
        data
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          // Aquí puedes ejecutar cualquier lógica adicional que necesites
        }
        if (callback) {
          callback(result);
        }
      });
    }
  }

  showConfirmation(data, callback?: (result: any) => void) {
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      id: 'confirmationDialog',
      width: '600px',
      data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // Aquí puedes ejecutar cualquier lógica adicional que necesites
      }
      if (callback) {
        callback(result);
      }
    });
  }

  showHelpExample(data, callback?: (result: any) => void) {
    if (!this.dialog.getDialogById('genericSuccessDialog')) {
      const dialogRef = this.dialog.open(ExampleModalComponent, {
        id: 'genericExampleDialog',
        width: '500px',
        data
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          // Aquí puedes ejecutar cualquier lógica adicional que necesites
        }
        if (callback) {
          callback(result);
        }
      });
    }
  }
}
