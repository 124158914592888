import { Component, ElementRef, HostBinding, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { Router, RouterLink } from '@angular/router';
import { UserService } from 'src/app/login/services/user/user.service';

import { NavWalletComponent } from '../../../dashboard/bullet/components/nav-wallet/nav-wallet.component';
import { NgIf, NgClass } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    standalone: true,
    imports: [NgIf, NgClass, RouterLink, NavWalletComponent, MatButtonModule, MatMenuModule, TranslateModule]
})
export class HeaderComponent implements OnInit {
  public isLogin: boolean;
  @Input() menuClose = false;
  userTitleMenu: string = 'Menu';
  constructor(public auth: UserService, public translate: TranslateService, private router: Router) {

  }

  ngOnInit() {
    if (this.auth.isAuthenticated()) {
      const userEmail = this.auth.getUser().email;
      this.userTitleMenu = userEmail.substring(0, userEmail.indexOf('@'));
    }
  }
  closeSession() {
    this.auth.closeSession();
  }
}
