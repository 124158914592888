import { CampaignDataInterface } from '../../models/campaignData.interface';
import { DataProduct } from '../../models/data-product';
import { DataBudget } from './../../models/data-budget';
import { DataTarget } from './../../models/data-target';
import { DataVideo } from './../../models/data-video';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class NewAdService {
  step:number = 1;
  getDataTarget(): DataTarget {
    console.log(JSON.parse(this.getFromStorage('dataTarget')));
    return JSON.parse(this.getFromStorage('dataTarget'));
  }
  getDataVideo(): DataVideo {
    return JSON.parse(this.getFromStorage('dataVideo'));
  }
  getDataBudget(): DataBudget {
    return JSON.parse(this.getFromStorage('dataBudget'));
  }
  getDataProduct(): DataProduct {
    return JSON.parse(this.getFromStorage('dataProduct'));
  }
  getCampaignData(): CampaignDataInterface {
    return JSON.parse(this.getFromStorage('dataCampaign'));
  }
  video;
  constructor(private httpSvc: HttpClient) { }
  private saveToStorage(key: string, data) {
    localStorage.setItem(key, data);
  }
  getVideo() {
    return this.video;
  }
  async getTempVideo() {
    if(this.getDataVideo() == null) return null;
    const url = this.getDataVideo().tempVideoUrl;
    const response = await fetch(url)
    const contentType = response.headers.get('content-type')
    const blob = await response.blob()
    const file = new File([blob], 'tempVideo')
    return file;
  }
  async getTempVideoFromUrl(url) {
    const response = await fetch(url)
    const contentType = response.headers.get('content-type')
    const blob = await response.blob()
    const file = new File([blob], 'tempVideo')
    return file;
  }
  private getFromStorage(key: string) {
    return localStorage.getItem(key);
  }
  setBudgetData(dataBudget: DataBudget) {
    this.saveToStorage('dataBudget', JSON.stringify(dataBudget));
  }
  setVideo(video,dataVideo: DataVideo) {
    this.video = video;
    this.saveToStorage('dataVideo', JSON.stringify(dataVideo));
  }
  setOnlyVideo(video) {
    this.video = video;
  }
  setVideoData(dataVideo: DataVideo) {
    this.saveToStorage('dataVideo', JSON.stringify(dataVideo));
  }
  setDataTarget(dataTarget: DataTarget) {
    this.saveToStorage('dataTarget', JSON.stringify(dataTarget));
  }
  setDataProduct(dataProduct: DataProduct) {
    this.saveToStorage('dataProduct', JSON.stringify(dataProduct));
  }
  setCampaignData(dataCampaign: CampaignDataInterface) {
    this.saveToStorage('dataCampaign', JSON.stringify(dataCampaign));
  }
  clearAdData(){
    localStorage.removeItem('dataBudget');
    localStorage.removeItem('dataVideo');
    localStorage.removeItem('dataTarget');
    localStorage.removeItem('dataProduct');
    localStorage.removeItem('dataCampaign');
    this.video = undefined;
  }
  clearVideoData(){
    this.video = undefined;
    localStorage.removeItem('dataVideo');
  }
}
