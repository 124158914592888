import { Component, Input, OnInit } from '@angular/core';
import { Router, NavigationEnd, RouterLink } from '@angular/router';
import { UserService } from 'src/app/login/services/user/user.service';
import { TranslateModule } from '@ngx-translate/core';
import { CurrencyPipe, NgIf } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { BodyTextComponent } from 'src/app/shared/components/atomic-design/molecules/body-text/body-text.component';

@Component({
    selector: 'app-nav-wallet',
    templateUrl: './nav-wallet.component.html',
    styleUrls: ['./nav-wallet.component.scss'],
    standalone: true,
    imports: [MatButtonModule, MatMenuModule, RouterLink, CurrencyPipe, TranslateModule, NgIf,BodyTextComponent]
})
export class NavWalletComponent implements OnInit {
  money:number = 0;
  @Input() noIcon = false;
  constructor(private auth: UserService,private router: Router) { 
    router.events.subscribe((val) => {
        // see also 
        if(val instanceof NavigationEnd){
          if(val.url == '/ads' || val.url == '/bullet' || val.url.includes('/ad')){
            console.log("wallet updated",this.auth.getUser().money);
            this.money = this.auth.getUser().money;
          }
        }
    });
  }

  ngOnInit(): void {
    this.money = this.auth.getUser().money;
  }

}
