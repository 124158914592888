import { Component, Inject, OnInit } from '@angular/core';
import { BodyTextComponent } from '../body-text/body-text.component';
import { ModalHeaderComponent } from '../modal-header/modal-header.component';
import { SecondaryTitleComponent } from '../secondary-title/secondary-title.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SecondaryButtonComponent } from '../secondary-button/secondary-button.component';

@Component({
  selector: 'app-generic-image-modal',
  templateUrl: './generic-image-modal.component.html',
  styleUrls: ['./generic-image-modal.component.scss'],
  standalone: true,
  imports:[
    ModalHeaderComponent,
    SecondaryTitleComponent,
    BodyTextComponent,
    SecondaryButtonComponent
  ]
})
export class GenericImageModalComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<GenericImageModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {titleModal:string,title:string,body:string,type:'error'|'success'|'warning'|'info'}) { }

  goBack() {
    this.dialogRef.close();
  }

  ngOnInit() {
  }

  continue(event) {
    this.dialogRef.close(event);
  }
  formError(event) {

  }

}
