import { CampaignDataInterface } from "../../ads/models/campaignData.interface";

export const BASIC_USERSCREENS_PATHS = {
    'basic': {
        'paths': {
            'A': {
                'create-ad/screens-selection': {
                    title: 'Pantallas',
                    icon: 'crop_portrait',
                    step: 1,
                    back: 'create-ad/campaign-type',
                    next: 'create-ad/upload-video',
                    validate: function (campaignData: CampaignDataInterface) {
                        return !campaignData.campaignType || !campaignData.pathVariation;
                    }
                },
                'create-ad/upload-video': {
                    title: 'Vídeo',
                    icon: 'videocam',
                    step: 2,
                    back: 'create-ad/screens-selection',
                    next: 'create-ad/ad-title',
                    validate: function (campaignData: CampaignDataInterface) {
                        return !campaignData.screensSelected;
                    }
                },
                'create-ad/ad-title': {
                    title: 'Título',
                    icon: 'local_offer',
                    step: 3,
                    back: 'create-ad/upload-video',
                    next: 'create-ad/publication-dates-with-days',
                    validate: function (campaignData: CampaignDataInterface) {
                        return !campaignData.screensSelected;
                    }
                },
                'create-ad/publication-dates-with-days': {
                    title: 'Programación',
                    icon: 'today',
                    step: 4,
                    back: 'create-ad/ad-title',
                    next: 'create-ad/target',
                    validate: function (campaignData: CampaignDataInterface) {
                        return;
                    }
                },
                'create-ad/target': {
                    title: 'Público',
                    icon: 'people_alt',
                    step: 5,
                    back: 'create-ad/publication-dates-with-days',
                    next: 'create-ad/summary-and-create',
                    validate: function (campaignData: CampaignDataInterface) {
                        return;
                    }
                },
                'create-ad/summary-and-create': {
                    title: 'Resumen',
                    icon: 'description',
                    step: 6,
                    back: 'create-ad/target',
                    next: 'create-ad/contract',
                    validate: function (campaignData: CampaignDataInterface) {
                        return;
                    }
                }
            }
        },
        'benefits': [
            {
                icon: 'watch_later',
                name: 'Tiempo de publicación',
                items: [
                    'Inmediato'
                ]
            },
            {
                icon: 'settings',
                name: 'Gestión del anuncio',
                items: [
                    'Selección de pantallas',
                    'Fecha de inicio y fin',
                    'Configuración de franja horaria',
                    'Selección de público objetivo según su sexo y edad'
                ]
            },
            {
                icon: 'trending_up',
                name: 'Datos en tiempo real de tu anuncio',
                items: [
                    'Número de personas alcanzadas',
                    'Número de visualizaciones',
                    'Tiempo de visualización promedio',
                    'Sexo y edad de las personas que visualizaron',
                    'Rendimiento por fecha y hora'
                ]
            }
        ],
    }
}