import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { DataVideo } from '../../ads/models/data-video';
import { NewAdService } from '../../ads/services/new-ad/new-ad.service';
import { CampaignDataInterface } from '../../ads/models/campaignData.interface';
import { CREATE_AD_PATHS } from '../create-ad-paths';


@Injectable({
  providedIn: 'root'
})
export class CreateAdMarketplaceGuard {
  activateRoute: string;
  dataVideo: DataVideo = null;
  campaignData: CampaignDataInterface = null;
  constructor(public newAdService: NewAdService, public router: Router) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.dataVideo = this.newAdService.getDataVideo();
    this.campaignData = this.newAdService.getCampaignData();
    this.activateRoute = route.routeConfig.path;
    let file = this.newAdService.getVideo();
    console.log(route);

    if (this.campaignData) {
      if (this.activateRoute != 'create-ad/network-selection' && this.activateRoute != 'create-ad/campaign-type' && this.activateRoute != 'create-ad/how-works') {
        this.validateSingleRoute(this.activateRoute);
      }
    } else {
      this.newAdService.setCampaignData({
        pathVariation: null,
        campaignType: null,
        dataBudget: null,
        dataTarget: null,
        dataProduct: null,
        dataVideo: null,
        completedSteps: 0
      });
      this.router.navigateByUrl("create-ad/network-selection");
    }
    return true;
  }

  validateSingleRoute(route: string) {
    console.log(this.campaignData.networkType, this.campaignData.campaignType, this.campaignData.pathVariation, route);
    const routeItem = CREATE_AD_PATHS[this.campaignData.networkType][this.campaignData.campaignType]['paths'][this.campaignData.pathVariation][route];
    console.log(routeItem);
    if (routeItem.validate(this.campaignData)) {
      this.router.navigateByUrl(routeItem.back);
    }
  }
}
