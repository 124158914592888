import { Component, Inject, OnInit } from '@angular/core';
import { PrimaryButtonComponent } from '../primary-button/primary-button.component';
import { BodyTextComponent } from '../body-text/body-text.component';
import { ModalHeaderComponent } from '../modal-header/modal-header.component';
import { SecondaryButtonComponent } from '../secondary-button/secondary-button.component';
import { SecondaryTitleComponent } from '../secondary-title/secondary-title.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GenericImageModalComponent } from '../generic-image-modal/generic-image-modal.component';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
  standalone: true,
  imports:[
    ModalHeaderComponent,
    SecondaryTitleComponent,
    BodyTextComponent,
    SecondaryButtonComponent,
    PrimaryButtonComponent,
  ]
})
export class ConfirmationModalComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<GenericImageModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {title:string,modalTitle:string,confirmButton:string,body:string}) { }

  goBack() {
    this.dialogRef.close();
  }

  ngOnInit() {
  }

  continue(event) {
    this.dialogRef.close(event);
  }
  formError(event) {

  }

}
