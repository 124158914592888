import { environment } from './../environments/environment.prod';
import { Component, isDevMode } from '@angular/core';
import { filter } from 'rxjs/operators';
import { Router, NavigationEnd, RouterOutlet, NavigationStart, NavigationCancel, NavigationError } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FooterComponent } from './shared/components/footer/footer.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { MenuDashboardComponent } from './shared/components/menu-dasboard/menu-dasboard.component';
import { NgIf, NgClass } from '@angular/common';
import { LoaderComponent } from "./shared/components/loader/loader.component";
declare let gtag: Function;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [NgIf, MenuDashboardComponent, NgClass, HeaderComponent, RouterOutlet, FooterComponent, LoaderComponent]
})
export class AppComponent {
  title = 'Meatspace Dashboard';
  activetransition = false;
  hide = false;
  isLogin: boolean;
  menuClose = false;
  isDev = false;
  loading = false;

  constructor(public router: Router, public translate: TranslateService) {
    this.router.events.subscribe((event) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.loading = true;
          break;
        }

        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this.loading = false;
          break;
        }
        default: {
          break;
        }
      }
    });
    translate.addLangs(['en', 'es']);
    translate.setDefaultLang('es');
    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en|es/) ? browserLang : 'en');
    this.initializeAnalytics();
    this.router.events.subscribe((path: any) => {
      if (path.url) {
        if (path.url === '/login' || path.url && path.url === 'login' || path.url.includes('login')
         || path.url === '/signup' || path.url && path.url === 'signup' || path.url.includes('signup')
         || path.url === '/activate' || path.url && path.url === 'activate' || path.url.includes('activate')
         || path.url === '/change-password' || path.url && path.url === 'change-password' || path.url.includes('change-password')
         /*|| path.url === '/'*/) {
          this.isLogin = true;
        } else {
          this.isLogin = false;
        }
      }
    });
    if (isDevMode()) {
      this.isDev = isDevMode();
    }
  }
  initializeAnalytics() {
    if (environment.gtag !== '') {
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          let title = event.urlAfterRedirects.replace(/\//g, ' ').substring(1);
          gtag('config', environment.gtag,
            {
              'page_path': event.urlAfterRedirects,
              'page_location': event.urlAfterRedirects,
              'page_title': title,
              'send_page_view': true,
            })
        }
      });
    }
  }
}
