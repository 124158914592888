import { Component, Inject, OnInit } from '@angular/core';
import { BodyTextComponent } from '../body-text/body-text.component';
import { ModalHeaderComponent } from '../modal-header/modal-header.component';
import { SecondaryButtonComponent } from '../secondary-button/secondary-button.component';
import { SecondaryTitleComponent } from '../secondary-title/secondary-title.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgFor } from '@angular/common';

@Component({
  selector: 'app-example-modal',
  templateUrl: './example-modal.component.html',
  styleUrls: ['./example-modal.component.scss'],
  standalone: true,
  imports: [
    ModalHeaderComponent,
    SecondaryTitleComponent,
    BodyTextComponent,
    SecondaryButtonComponent,
    NgFor
  ]
})
export class ExampleModalComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ExampleModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  goBack() {
    this.dialogRef.close();
  }

  ngOnInit() {
  }

  continue(event) {
    this.dialogRef.close(event);
  }
  formError(event) {

  }

}
