<div class="confirmation-modal">
  <app-modal-header [title]="data.modalTitle ? data.modalTitle : '¿ Estas Seguro ?'">
    <div end (click)="goBack()">x</div>
  </app-modal-header>
  <div class="modal-cont">
    <app-secondary-title>{{data.title}}</app-secondary-title>
    <app-body-text>{{data.body}}</app-body-text>
    <div class="mt-3 d-flex w-100 justify-content-around">
      <app-secondary-button (click)="goBack()">Cancelar</app-secondary-button>
      <app-primary-button (click)="continue(true)">{{ data.confirmButton ? data.confirmButton : 'Aceptar' }}</app-primary-button>
    </div>
  </div>
</div>
