<div class="generic-image-modal">
  <app-modal-header [title]="data.titleModal">
    <div end (click)="goBack()">x</div>
  </app-modal-header>
  <div class="modal-cont">
    <app-secondary-title>{{data.title}}</app-secondary-title>
    <app-body-text>{{data.body}}</app-body-text>
    <img src="/assets/svgs/{{data.type}}.svg" class="w-75 m-auto" alt="">
    <div class="mt-3">
      <app-secondary-button (click)="continue(true)">Aceptar</app-secondary-button>
    </div>
  </div>
</div>
