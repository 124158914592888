import { Component, Inject, OnInit } from '@angular/core';
import { ModalHeaderComponent } from '../modal-header/modal-header.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SecondaryTitleComponent } from '../secondary-title/secondary-title.component';
import { BodyTextComponent } from '../body-text/body-text.component';
import { JsonPipe } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';
import { SecondaryButtonComponent } from '../secondary-button/secondary-button.component';

@Component({
  selector: 'app-generic-error-modal',
  templateUrl: './generic-error-modal.component.html',
  styleUrls: ['./generic-error-modal.component.scss'],
  standalone: true,
  imports: [
    ModalHeaderComponent,
    SecondaryTitleComponent,
    BodyTextComponent,
    JsonPipe,
    MatExpansionModule,
    SecondaryButtonComponent
  ]
})
export class GenericErrorModalComponent implements OnInit {
  errorForHuman = {
    0: {
      label: 'No conection with server',
      description: 'No conection with server.'
    },
    400: {
      label: 'Bad Request',
      description: 'The server could not understand the request due to invalid syntax.'
    },
    401: {
      label: 'Unauthorized',
      description: 'You must be authenticated to access this resource.'
    },
    403: {
      label: 'Forbidden',
      description: 'You do not have permission to access this resource.'
    },
    404: {
      label: 'Not Found',
      description: 'The requested resource could not be found on the server.'
    },
    500: {
      label: 'Internal Server Error',
      description: 'The server encountered an unexpected condition which prevented it from fulfilling the request.'
    },
    502: {
      label: 'Bad Gateway',
      description: 'The server received an invalid response from the upstream server.'
    },
    503: {
      label: 'Service Unavailable',
      description: 'The server is currently unable to handle the request due to a temporary overload or scheduled maintenance.'
    },
    504: {
      label: 'Gateway Timeout',
      description: 'The server did not receive a timely response from the upstream server.'
    }
  };
  

  constructor(public dialogRef: MatDialogRef<GenericErrorModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  goBack() {
    this.dialogRef.close();
  }

  ngOnInit() {
  }

  continue(event) {
    this.dialogRef.close(event);
  }
  formError(event) {

  }
}
