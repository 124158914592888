<div class="generic-example-modal">
  <app-modal-header title="{{data.modalTitle}}">
    <div end (click)="goBack()">x</div>
  </app-modal-header>
  <div class="modal-cont">
    <app-secondary-title>{{data.title}}</app-secondary-title>
    <app-body-text>Ejemplo:</app-body-text>
    <app-body-text [gray]="true">{{data.description}}</app-body-text>
    <app-body-text [gray]="true">
      <ul>
        <li *ngFor="let item of data.examples">{{item}}</li>
      </ul>
    </app-body-text>
    <div class="mt-5 text-center">
      <app-secondary-button (click)="continue(true)">Aceptar</app-secondary-button>
    </div>
  </div>
</div>